import axios from '@axios'

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    fetchOrderDetails(context, id) {
      return new Promise((resolve, reject) => {
        axios.get(`storefront/bookings/${id}`).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
