<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
// const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')

export default {
  // metaInfo() {
  //   return {
  //     script: [
  //       {
  //         type: 'application/ld+json',
  //         json: {
  //           '@context': 'https://schema.org/',
  //           '@type': 'SoftwareApplication',
  //           name: 'tobook.link',
  //           image: 'https://static-storefront.tobook.link/assets/img/tobooklink_logo.73323610.svg',
  //           applicationCategory: 'https://schema.org/SaaSApplication',
  //           applicationSubCategory: 'Online Booking System',
  //           operatingSystem: 'Web, iOS, Android',
  //           applicationSuite: 'iOS, Android',
  //           description: 'tobook.link is a comprehensive, free online booking system designed for professionals to streamline their service offerings. Our platform offers a seamless integration with iOS and Android apps, supports different time zones for accurate scheduling, and provides smart scheduling options for businesses. With automatic notifications, personal time blocks, and data import capabilities, we ensure efficient schedule management. Our platform also enables the creation and management of a dedicated booking page, provides a customizable booking widget, and generates QR codes for quick access. With design customization options, businesses can align their booking page with their branding. Our staff management API facilitates the creation and management of employee profiles and services, and provides access control. Our client management API automatically creates customer profiles, allows for customer data export, and collects and displays customer reviews. Experience the convenience of managing bookings, staff, and clients all in one place with tobook.link.',
  //           aggregateRating: { '@type': 'AggregateRating', ratingValue: '5', ratingCount: '100' },
  //           sameAs: ['https://www.facebook.com/profile.php?id=100094038565247', 'https://www.youtube.com/@tobooklink', 'https://www.instagram.com/tobook.link', 'https://apps.apple.com/gb/app/tobook-link/id6450151750', 'https://play.google.com/store/apps/details?id=org.nativescript.toBookLink'],
  //           featureList: ['Seamless integration with mobile apps', 'Supports different time zones', 'Provides smart scheduling options', 'Sends automatic notifications', 'Allows for personal time blocks and data import', 'Enables creation and management of a dedicated booking page', 'Provides a customizable booking widget', 'Generates QR codes for quick access', 'Allows for design customization', 'Facilitates creation and management of employee profiles and services', 'Provides access control', 'Automatically creates customer profiles', 'Allows for customer data export', 'Collects and displays customer reviews', 'Booking page', 'Booking widget'],
  //           screenshot: ['https://storage.googleapis.com/static-assets.tobook.link/logo/icon.png'],
  //         },
  //       },
  //     ],
  // meta: [
  // {
  //   vmid: 'keywords',
  //   name: 'keywords',
  //   content: this.$t('meta.keywords'),
  // },
  // {
  //   vmid: 'description',
  //   name: 'description',
  //   content: this.$t('meta.description'),
  // },
  // {
  //   vmid: 'og:title',
  //   name: 'og:title',
  //   content: 'tobook.link',
  // },
  // {
  //   vmid: 'og:description',
  //   name: 'og:description',
  //   content: 'Free online booking system for Web & Social Media',
  // },
  // {
  //   vmid: 'twitter:title',
  //   name: 'twitter:title',
  //   content: 'tobook.link',
  // },
  // {
  //   vmid: 'twitter:description',
  //   name: 'twitter:description',
  //   content: 'Free online booking system for Web & Social Media',
  // },
  // ],
  //   }
  // },
  components: {

    // Layouts
    LayoutFull,
    // LayoutVertical,

  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      // if (this.$route.meta.layout === 'full') return 'layout-full'
      // if (this.$route.meta.layout === 'vertical') return `layout-${this.contentLayoutType}`
      return 'layout-full'
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses, isRTL } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    if (localStorage.getItem('locale') === 'ar') isRTL.value = true

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>
