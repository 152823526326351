import Vue from 'vue'
import VueMeta from 'vue-meta'
import {
  ToastPlugin, ModalPlugin, SidebarPlugin, IconsPlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import VueMask from 'v-mask'
import VCalendar from 'v-calendar'
// phone libs
// import VueTelInput from 'vue-tel-input'
import VuePhoneNumberInput from 'vue-phone-number-input'

import SocialSharing from 'vue-social-sharing'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import i18n from '@/libs/i18n'

// phone input
// import 'vue-tel-input/dist/vue-tel-input.css'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

Vue.component('vue-phone-number-input', VuePhoneNumberInput)
// Vue.use(VuePhoneNumberInput)

Vue.use(VueMeta)
Vue.use(SocialSharing)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(IconsPlugin)
Vue.use(SidebarPlugin)
Vue.use(VCalendar, {
  componentPrefix: 'v',
})
// Vue.use(VueTelInput)

Vue.use(VueMask)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
