import axios from '@axios'

export default {
  state: {
    timeslots: [],
    countdown: '',
    services: {},
    currency: '',
    orgServicesForBookingPage: [],
    orgWorkspacesForBookingPage: [],
    workspaceServicesForBookingPage: [],
    code: localStorage.getItem('code') || null,
  },
  getters: {
    getTimeslots(state) {
      return state.timeslots
    },
    getCountdown(state) {
      return state.countdown
    },
    getServices(state) {
      return state.services
    },
    getCurrency(state) {
      return state.currency
    },
    getCode: state => state.code,
    getOrgServicesForBookingPage(state) {
      return state.orgServicesForBookingPage
    },
    getOrgWorkspacesForBookingPage(state) {
      return state.orgWorkspacesForBookingPage
    },
    getWorkspaceServicesForBookingPage(state) {
      return state.workspaceServicesForBookingPage
    },
  },
  mutations: {
    UPDATE_COUNTDOWN(state, data) {
      state.countdown = data
    },
    UPDATE_TIMESLOTS(state, data) {
      state.timeslots = data
    },
    updateServices(state, data) {
      state.services = data
    },
    updateServiceStatusAvailable(state, { index, value }) {
      state.services[index].is_available = value
    },
    UPDATE_CURRENCY(state, data) {
      state.currency = data
    },
    UPDATE_CODE(state, data) {
      localStorage.setItem('code', data)
      state.code = data
    },
    updateOrgServicesForBookingPage(state, data) {
      state.orgServicesForBookingPage = data
    },
  },
  actions: {
    confirmation(context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`/storefront/records/confirm/${data.record}`).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrgServicesForBookingPage(context, { organization, page, source }) {
      return new Promise((resolve, reject) => {
        axios.get(`/storefront/organizations/${organization}?s=${source}`)
          .then(response => {
            context.commit('updateOrgServicesForBookingPage', response.data)
            context.commit('UPDATE_CURRENCY', response.data.currency)
            if (page !== 'widget') {
              context.commit('appConfig/UPDATE_SKIN', response.data.background)
            }
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchOrgServicesForWidget(context, { uuid, page, source }) {
      return new Promise((resolve, reject) => {
        axios.get(`/storefront/organizations/widget/${uuid}?${source}`)
          .then(response => {
            context.commit('updateOrgServicesForBookingPage', response.data)
            context.commit('UPDATE_CURRENCY', response.data.currency)
            if (page !== 'widget') {
              context.commit('appConfig/UPDATE_SKIN', response.data.background)
            }
            // context.commit('updateLogo', response.data.logo_small)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchTimeslotsForOneWorkspace(context, { workspaceId, serviceId }) {
      return new Promise((resolve, reject) => {
        axios.post(`/storefront/timeslots/multiple-days/${workspaceId}`, { services: [serviceId] })
          .then(response => {
            // context.commit('updateWorkspaceServicesForBookingPage', [])
            resolve(response.data.records_by_workspace)
          })
          .catch(error => reject(error))
      })
    },
    fetchTimeslotsAndCountdownLikeGuest(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/storefront/timeslots/single-day/${data.workspace}`, data)
          .then(response => {
            context.commit('UPDATE_COUNTDOWN', response.data.countdown)
            context.commit('UPDATE_TIMESLOTS', response.data.timeslots)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    setRecordLikeGuest(context, formData) {
      return new Promise((resolve, reject) => {
        axios.post(`/storefront/records/create/${formData.workspace}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
