import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Home = () => import(/* webpackChunkName: "app.home" */ '@/views/Home.vue')
const Faq = () => import(/* webpackChunkName: "app.faq" */ '@/views/landing/faq/Faq.vue')
const Pricing = () => import(/* webpackChunkName: "app.faq" */ '@/views/landing/pricing/Pricing.vue')
const PrivacyPolicy = () => import(/* webpackChunkName: "app.privacy-policy" */ '@/views/landing/policy/PrivacyPolicy.vue')
const TermsOfUse = () => import(/* webpackChunkName: "app.terms-of-use" */ '@/views/landing/policy/TermsOfUse.vue')
const Partners = () => import(/* webpackChunkName: "app.partners" */ '@/views/landing/contacts/Partners.vue')
const Contacts = () => import(/* webpackChunkName: "app.contacts" */ '@/views/landing/contacts/Contacts.vue')
const BookingPage = () => import(/* webpackChunkName: "app.booking-page" */ '@/views/booking-page/BookingPage.vue')
const ConfirmationOfRecording = () => import(/* webpackChunkName: "app.booking-confirmation" */ '@/views/booking-page/ConfirmationOfRecording.vue')
const ReviewFromEmail = () => import(/* webpackChunkName: "app.booking-feedback" */ '@/views/review/ReviewFromEmail.vue')
const OrderPage = () => import('@/views/booking-page/OrderPage.vue')
const EmployeeSecondPage = () => import('@/views/booking-page/twoPageEmployeeView/EmployeeSecondPage.vue')
const ServiceSecondPage = () => import('@/views/booking-page/twoPageServiceView/ServiceSecondPage.vue')

const languages = ['ar', 'en', 'ru', 'ko', 'cs', 'fr', 'de', 'pt', 'es', 'hi', 'it', 'ja', 'pl', 'sv', 'tr', 'zh', 'cn', 'uk', 'hu', 'nl']
const regexString = `/:lang(${languages.join('|')})`

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior(to, from, savedPosition) {
    if (to.path === from.path && to.query !== from.query) {
      return // Не меняем положение прокрутки
    }
    // Для остальных случаев возвращаем позицию прокрутки по умолчанию
    if (savedPosition) {
      // eslint-disable-next-line consistent-return
      return savedPosition
    }
    // eslint-disable-next-line consistent-return
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: () => `/${localStorage.getItem('locale') || 'en'}`,
    },
    {
      path: '/privacy',
      redirect: () => `/${localStorage.getItem('locale') || 'en'}/privacy`,
    },
    {
      path: '/terms',
      redirect: () => `/${localStorage.getItem('locale') || 'en'}/terms`,
    },
    {
      path: '/partners',
      redirect: () => `/${localStorage.getItem('locale') || 'en'}/partners`,
    },
    {
      path: '/contacts',
      redirect: () => `/${localStorage.getItem('locale') || 'en'}/contacts`,
    },
    {
      path: '/faq',
      redirect: () => `/${localStorage.getItem('locale') || 'en'}/faq`,
    },
    {
      path: '/pricing',
      redirect: () => `/${localStorage.getItem('locale') || 'en'}/pricing`,
    },
    {
      path: regexString,
      component: {
        render: h => h('router-view'),
      },
      children: [
        {
          path: '',
          name: 'home',
          component: Home,
          meta: {
            layout: 'full',
            requiresVisitor: true,
          },
        },
        {
          path: 'faq',
          name: 'faq',
          component: Faq,
          meta: {
            layout: 'full',
          },
        },
        {
          path: 'pricing',
          name: 'pricing',
          component: Pricing,
          meta: {
            layout: 'full',
          },
        },
        {
          path: 'privacy',
          name: 'privacy',
          component: PrivacyPolicy,
          meta: {
            layout: 'full',
          },
        },
        {
          path: 'terms',
          name: 'terms',
          component: TermsOfUse,
          meta: {
            layout: 'full',
          },
        },
        {
          path: 'partners',
          name: 'partners',
          component: Partners,
          meta: {
            layout: 'full',
          },
        },
        {
          path: 'contacts',
          name: 'contacts',
          component: Contacts,
          meta: {
            layout: 'full',
          },
        },
      ],
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/:id',
      name: 'booking-page',
      props: true,
      component: BookingPage,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/:id/employee/:employee',
      name: 'employee-second-page',
      props: true,
      component: EmployeeSecondPage,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/:id/service/:service',
      name: 'service-second-page',
      props: true,
      component: ServiceSecondPage,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/confirmation/:record/:org',
      component: ConfirmationOfRecording,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/review/:token',
      component: ReviewFromEmail,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/order/:token',
      name: 'order',
      component: OrderPage,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/full-payment/:token',
      name: 'full-payment',
      component: OrderPage,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

export default router
