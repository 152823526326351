import axios from '@axios'

export default {
  state: {
    infoWorkspace: '',
  },
  getters: {
    getInfoWorkspace(state) {
      return state.infoWorkspace
    },
  },
  mutations: {
    UPDATE_INFO_WORKSPACE(state, data) {
      state.infoWorkspace = data
    },
  },
  actions: {
    fetchLogoOrgAndSpecialist(context, workspace) {
      return new Promise((resolve, reject) => {
        axios.get(`/storefront/workspaces/review/info/${workspace}`)
          .then(response => {
            context.commit('UPDATE_INFO_WORKSPACE', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    createReview(context, formData) {
      return new Promise((resolve, reject) => {
        axios.post(`/storefront/workspaces/review/create/${formData.get('workspace')}`, formData)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
