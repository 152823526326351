import axios from '@axios'
import i18n from '@/libs/i18n'

export default {
  state: {
    fakeWidgetSettings: {
      showColorInput: false,
      showMainColorInput: false,
      position: 'right',
      lat: 2,
      long: 2,
      animation: 'animation-3',
      buttonColor: '#4b66f8',
      buttonIcon: 'two_lines',
      firstLine: i18n.t('widget.widget'),
      secondLine: `${i18n.t('basic.here')}✌`,
      title: i18n.t('widget.booking-page'),
      rightTab: i18n.tc('basic.specialist', 2),
      leftTab: i18n.t('basic.services'),
      size: 'sidebar-lg',
      show_rating: true,
      mainColor: '#4b66f8',
      currency: { sign: '€', name: 'Euro' },
      calendarType: 2,
    },
  },
  getters: {
    getFakeWidgetSettings(state) {
      return state.fakeWidgetSettings
    },
  },
  mutations: {
    UPDATE_FAKE_WIDGET_SETTINGS(state, { key, value }) {
      state.fakeWidgetSettings[key] = value
    },
    UPDATE_INPUT_COLOR(state, bool) {
      state.fakeWidgetSettings.showColorInput = bool
    },
    UPDATE_INPUT_MAIN_COLOR(state, bool) {
      state.fakeWidgetSettings.showMainColorInput = bool
    },
  },
  actions: {
    sendPartnerEmail(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('email/partner', {
          name: data.name,
          email: data.email,
          message: data.message,
        }).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendSupportEmail(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('email/support', data).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
