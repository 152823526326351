import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import landing from '@/store/modules/landing'
import booking from '@/store/modules/booking'
import review from '@/store/modules/review'
import order from '@/store/modules/order'
import payment from '@/store/modules/payment'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    landing,
    booking,
    review,
    order,
    payment,
  },
  strict: process.env.DEV,
})
