import Vue from 'vue'

// axios
import axios from 'axios'

const isProduction = process.env.NODE_ENV === 'production'
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: isProduction ? `https://${process.env.VUE_APP_BACKEND_API_HOSTNAME}/api` : 'http://127.0.0.1:8000/api',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
