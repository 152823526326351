import axios from '@axios'

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    fetchPaymentLink(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('payments/stripe/checkout/initiate/', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
